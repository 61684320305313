<template>
<FeedbackBox>
    <h1 class="auth-title indigo--text text--darken-4 mb-3">
      {{ $t('content.user_feedback') }}
    </h1>
    <form class="pt-3">
      <v-row>
        <v-col cols="12" md="6">
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup
                :name="$t('form.office')"
                :validator="$v.form.office">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.office')" />
                <v-autocomplete
                  v-model="form.office"
                  v-bind="attrs"
                  outlined
                  dense
                  class="rounded-lg"
                  :placeholder="$t('feedbackForm.select_office')"
                  :items="office_list"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-col>
        <v-col cols="12" md="6">
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup
                :name="$t('feedbackForm.portal_feature')"
                :validator="$v.form.feature">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('feedbackForm.portal_feature')" />
                <v-autocomplete
                  v-model="form.feature"
                  v-bind="attrs"
                  outlined
                  dense
                  class="rounded-lg"
                  :placeholder="$t('feedbackForm.select_feature')"
                  :items="feature_list"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="ml-4 mr-4">
        <v-col cols="12" class="px-0 pb-0 pt-5">
          <BaseFormGroup
              :name="$t('feedbackForm.detailed_comments')"
              :validator="$v.form.detailed_comments">
            <template slot-scope="{ attrs }">
              <BaseLabel :title="$t('feedbackForm.detailed_comments')" />
              <v-textarea
                v-model="form.detailed_comments"
                v-bind="attrs"
                :placeholder="$t('feedbackForm.enter_detailed_comment')"
                outlined
                dense
                tabindex="5"
                class="rounded-lg"
                @input="$v.form.detailed_comments.$touch()"
                @blur="$v.form.detailed_comments.$touch()"
              ></v-textarea>
            </template>
          </BaseFormGroup>
        </v-col>
      </v-row>
    </form>
    <div class="pl-3 mt-4">
      <v-btn
        class="mr-1 indigo white--text no-transform"
        x-large
        @click.stop="submit"
      >
        {{ $t('feedbackForm.submit') }}
      </v-btn>
    </div>
  </FeedbackBox>
</template>


<script>
import FeedbackBox from '@/components/feedback/FeedbackBox';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'index',
  components: {
    FeedbackBox,
  },
  validations: function() {
    return {
      form: {
        office: {
          required,
        },
        feature: {
          required,
        },
        detailed_comments: {
          required,
        },
      },
    };
  },
  data: () => ({
    feature_list: [],
    office_list: [],
    form: {
      feature: '',
      office: '',
      detailed_comments: '',
    },
  }),
  computed: {
  },
  created() {
    this.fetchOfficeList();
    this.fetchFeatureList();
  },
  methods: {
    fetchOfficeList() {
      window.axios
        .get('/employee_workbench/api/offices/',)
        .then(res => {
          this.office_list = res.data;
          return res;
        })
        .catch(err => {
          this.$toast.error(err.message);
        });
    },
    fetchFeatureList() {
      window.axios
        .get('/portal/api/topics/')
        .then(res => {
          this.feature_list = res.data;
          return res;
        })
        .catch(err => {
          this.$toast.error(err.message);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        window.axios
          .post('/portal/api/feedbacks/', this.form)
          .then(res => {
            this.$toast.success(`${this.$t('feedbackForm.success_msg')}`);
            window.setTimeout(function () {
              window.location.reload();
            },2000)
            return res;
          })
          .catch(err => {
            this.$toast.error(err.message);
          });
      }
    },
    ok() {
      this.$router.push({ name: 'index' });
    },
  },
};
</script>
<style lang="scss" scoped>
.no-transform {
  text-transform: none;
}
.no-decoration {
  text-decoration: none;
}
</style>
