<template>
  <div class="white">
    <v-container fluid class="container">
      <v-layout fill-height>
        <v-row>
        
          <v-col cols="12" :md="wideBg ? '7' : '8'">
            <div class="pt-12 pb-4 px-8">
              <slot />
            </div>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'FeedbackBox',
  props: {
    wideBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>
<style lang="scss">
.container {
  height: 100vh;
}
</style>
